import React from "react"
import {Helmet} from 'react-helmet'
import SEO from '../components/seo'
import { Link, graphql } from 'gatsby'
import Layout from "../layouts/layout-scroll"
import Image from 'gatsby-image'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next, collection } = this.props.pageContext
    return (
        <Layout>
          <Helmet htmlAttributes={{ class:'ride post' }} />
          <SEO title={`Ride: ${post.frontmatter.title}`}  />
          <div className="post-content-container" >
            <h1>{post.frontmatter.title}</h1>
            { post.frontmatter.subtitle && (
              <p>{post.frontmatter.subtitle}</p>
            )}
            { post.frontmatter.cover && (
            <Image
              fluid={post.frontmatter.cover.childImageSharp.fluid}
              style={{
                maxWidth: '1080px',
                height: 'auto',
                borderRadius: `0.4em 0.4em 0 0`,
              }}
              className="image_highlight"
            />
            )}
            <div className="post-content" dangerouslySetInnerHTML={{__html: post.html}}/>
            { collection && (
              <>
              <hr className="divider" />
              <ul
                style={{
                  display: `flex`,
                  flexWrap: `wrap`,
                  justifyContent: `space-between`,
                  listStyle: `none`,
                  padding: '1.5rem',
                  margin: 0
                }}
                className="post-footer"
              >
                { collection && (
                <li>
                  <div style={{ textAlign: 'center', padding: '3rem' }}>
                    <h2>
                      <Link to={collection.node.fields.slug} rel="next">
                        {collection.node.frontmatter.title}
                      </Link>
                    </h2>
                  </div>
                </li>
                )}
                {next && next.frontmatter.type === 'note' && (
                  <li>
                    <div>
                      <p className="title">A suivre...</p>
                      <Link to={next.fields.slug} rel="next">
                        {next.frontmatter.title}
                        <p className="post-excerpt">
                          {next.excerpt}
                        </p>
                      </Link>
                    </div>
                  </li>
                )}
                {previous && previous.frontmatter.type === 'note' && (
                  <li>
                    <div>
                      <p className="title">Précédemment</p>
                      <Link to={previous.fields.slug} rel="prev">
                        {previous.frontmatter.title}
                        <p className="post-excerpt">
                          {previous.excerpt}
                        </p>
                      </Link>
                    </div>
                  </li>
                )}
              </ul>
              </>
            )}
          </div>
        </Layout>
    )
  }
}

export default BlogPostTemplate

export const query = graphql`
  query BikePostQuery($slug: String!, $tag: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subtitle
        cover {
          childImageSharp {
            fixed {
              src
              srcSet
              width
              height
            }
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: {frontmatter: { type: { eq: "bike" }, visible: { eq: true}, tags: { eq: $tag}}},
      sort: { fields: [frontmatter___date], order: DESC},
    ) {
      edges {
        node {
          fields { slug }
        }
      }
    }
  }
`
